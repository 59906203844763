var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-snackbar',{attrs:{"color":_vm.snackbarColor,"multi-line":true,"timeout":_vm.timeout},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.errorText)+" ")]),_c('v-card',{attrs:{"elevation":"12","min-height":"600"}},[_c('v-card-title',[_vm._v("I miei acquisti")]),_c('v-card-text',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('br'),_c('br'),_c('br'),_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":70,"width":7}}),_c('br'),_c('br'),_c('br')],1)],1),_c('v-data-table',{attrs:{"elevation":"10","headers":_vm.headers,"items":_vm.purchases,"item-key":"ID","search":_vm.search,"footer-props":{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus',
          itemsPerPageText: 'Acquisti per pagina',
          itemsPerPageAllText: 'Tutti gli acquisti',
          itemsPerPageOptions: [10, 25, 50, 100, 500, -1],
          pageText: '',
        }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Filtra...","itemsPerPageText":"'Acquisti per pagina'"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"no-data",fn:function(){return [_c('v-sheet',[_vm._v(" Nessun acquisto ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-sheet',[_vm._v(" Nessun acquisto ")])]},proxy:true},{key:"item.ID",fn:function({ item }){return [_c('span',{class:item.closed ? 'text--disabled' : ''},[_vm._v(_vm._s(item.ID))])]}},{key:"item.description",fn:function({ item }){return [_c('span',{class:item.closed ? 'text--disabled' : ''},[_vm._v(_vm._s(item.description))])]}},{key:"item.startedText",fn:function({ item }){return [_c('span',{class:item.closed ? 'text--disabled' : ''},[_vm._v(_vm._s(item.startedText))])]}},{key:"item.UserText",fn:function({ item }){return [_c('span',{class:item.closed ? 'text--disabled' : ''},[_vm._v(_vm._s(item.UserText))])]}},{key:"item.stateText",fn:function({ item }){return [_c('span',{class:item.closed ? 'text--disabled' : ''},[_vm._v(_vm._s(item.stateText))])]}},{key:"item.modifiedText",fn:function({ item }){return [_c('span',{class:item.closed ? 'text--disabled' : ''},[_vm._v(_vm._s(item.modifiedText))])]}},{key:"item.competenzaText",fn:function({ item }){return [_c('div',{class:item.closed ? 'text--disabled pa-1' : 'pa-1',style:('color: #fff; background-color: ' +
              item.fsm.states[
                item.PurchaseHistories[item.PurchaseHistories.length - 1]
                  .state
              ].meta.color)},[_vm._v(" "+_vm._s(item.fsm.states[ item.PurchaseHistories[item.PurchaseHistories.length - 1] .state ].meta.responsible)+" ")])]}},{key:"item.financialOperator",fn:function({ item }){return [_c('span',{class:item.closed ? 'text--disabled' : ''},[_vm._v(_vm._s(item.financialOperator))])]}},{key:"item.started",fn:function({ item }){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","to":'/mypurchase/' + item.ID}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-eye-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualizza")])])]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }